.Player {
  color: rgb(116,149,190);
  position: relative;
  overflow: hidden;
}

.PlayerDetails {
  width: 100%;
  padding: 20px;
}

.PDAvatar {
  display: inline-block;
  outline: 1px solid rgb(116,149,190);
  margin-left: 20px;
  margin-right: 30px;
}

.PDAvatar img {
  width: 110px;
  height: 110px;
}

.PDText {
  display: inline-block;
  text-align: left;
  height: 80px;
}

.PDName {
  font-size: 2.4em;
  line-height: 115%;
}

.PDPlayed {
  font-size: 0.8em;
}

.PSCTitle, .MLTitle {
  text-align: center;
  font-size: 2em;
  width: 100%;
}

.PSCType, .PSCTotal {
  position: absolute;
  right: 10px;
}

.PSCType {
  display: none;
}

.PlayerStatsContainer {
  text-align: center;
  position: relative;
}

.PlayerStats, .PlayerMapStats {
  text-align: center;
}

.PlayerMatchList {
  text-align: center;
  background: rgba(8,15,20,0.6);
}

.PSCHeader {
  background: rgba(8,15,20,0.5);
}

.PSTop {
  background: rgba(8,15,20,0.5);
  padding: 20px;
}

.PSBot {
  background: rgba(8,15,20,0.75);
  padding: 20px;
}

.PSTop > div, .PSBot > div {
  display: inline-block;
}

.PSItem {
  padding: 0 20px 0 20px;
  text-align: center;
}

.PSLabel {
  width: 100%;
  font-size: 0.7em;
  margin-bottom: 2px;
}

.PSContent {
  width: 100%;
}

.SFHeader {
  cursor: pointer;
}

.MatchItem {

}

.MatchItem:nth-child(odd) {
  background: rgba(8,15,20,0.25);
}

.MIExpand, .MILink {
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.MIBasic {
  padding: 5px;
}

.MIExtra {
  font-size: 0.95em;
  padding: 5px;
}

.SortFilter {
  background: rgba(8,15,20,0.6);
}

.FilterContent {
  margin-left: 0;
  margin-right: 0;
  padding: 5px 15px 15px 15px;
}

.FCDate {
  padding-left: 15px;
  padding-right: 15px;
}

.SortContainer {
  display: none;
}

.SFHeader {
  text-align: right;
  padding: 5px;
  margin-right: 20px;
}

.SFHeader span {
  margin-right: 10px;
}
