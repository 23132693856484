body {
  margin: 0;
  padding: 0;
  background: rgba(30,38,45);
  background: radial-gradient(circle, rgba(197,208,217,1) 0%, rgba(119,146,168,1) 35%, rgba(3,4,5,1) 100%);
}

.App {
  font-family: sans-serif;
  /*background: url(../images/menubg.png);
  background-size: cover;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Header, .Footer {
  text-align: center;
  background: rgba(8,15,20,0.75);
  color: rgb(116,149,190);
  padding: 5px;
}

.Header > div {
  padding: 5px;
}

.HomeLink {
  font-size: 1.25em;
  text-align: center;
}

.HeaderSearch > div {
  display: inline-block;
}

.HeaderSearchLabel {
  margin-right: 10px;
}

.AppContent {
  flex: 1 0 auto;
  z-index: 1;
}

.Main {
  text-align: center;
  background: rgba(8,15,20,0.75);
  color: rgb(116,149,190);
  padding: 5%;
}

.SearchResults {
  margin-top: 20px;
}
